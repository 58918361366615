import { Link, navigate } from "gatsby"
import React from "react"
import { isMobile } from "../app.utils"

function CustomerPortalComponent(props) {
  const url = process.env.CLAIMS_PAGE_LINK

  const mobileText = {
    customerPortalHeader: "Kundenportal",
  }


  const tabletText = {
    customerPortalHeader: "Kundenportal für Schadenmeldungen",
  }
  return (
    <div className="container customer-portal-wrapper text-center">
      <div className="heading ">
        {isMobile
          ? mobileText["customerPortalHeader"]
          : tabletText["customerPortalHeader"]}
      </div>

      <div className="sub-heading font-light ">
        Ihr Zugriff auf Versicherungen und Schadenmeldungen
      </div>

      <div className="button-wrapper ">
        <Link to={url} >

        <button
          type="button"
          className="btn btn-secondary anim"
        >
          <span>Kundenportal öffnen</span>
        </button>
        </Link>
      </div>
    </div>
  )
}

export default CustomerPortalComponent
