import React from "react"
import ContactDetailsComponent from '../contact-details/contact-details.component'
import CustomerPortalComponent from '../customer-portal/customer-portal.component'
import HomeBanner from "../shared/home-banner"

function LandingComponent() {
  return (
    <>
      <HomeBanner />
      <CustomerPortalComponent/>
      <ContactDetailsComponent/>
    </>
  )
}

export default LandingComponent
