import React from "react"
import email from "../../assets/images/email.svg"
import contact from "../../assets/images/contact.svg"
import write from "../../assets/images/write.svg"
import { isMobile } from "../app.utils"
import { Link } from "gatsby"

const mobileText = {
  contactDetailsHeader: " Kontaktmöglichkeiten",
  contactDetailsSubHeader : `Montag - Samstag, <br/>8:00 - 18:00 (exkl. Feiertage)`
}

const tabletText = {
    contactDetailsHeader: "Weitere Kontaktmöglichkeiten",
    contactDetailsSubHeader : "Montag - Samstag, 8:00 - 18:00 (exkl. Feiertage)"
}

const ContactDetailsComponent = () => {
  return (
    <div className="text-center">
      <div className="contact-details-wrapper">
        <div className="heading ">
          {isMobile
            ? mobileText["contactDetailsHeader"]
            : tabletText["contactDetailsHeader"]}
        </div>

        <div className="sub-heading font-light" dangerouslySetInnerHTML={{__html:
           isMobile ?    mobileText["contactDetailsSubHeader"] : tabletText["contactDetailsSubHeader"]
       } }>

        </div>

        <div className="row text-center justify-content-center">
          <div className="contact-card  ">
            <div className="card-header col-sm-12 col-md-4 col-xl-12 ">
              <img src={email}></img>
              <span>Email</span>
            </div>

            <div className=" col-sm-12 col-md-8 col-xl-12 card-body">
            <div className="card-content">
              <div className="contact-detail">
                <div style={{marginBottom:'15px'}}><a href="mailto:drei-device-support@bolttech.at">drei-device-support@bolttech.at</a></div>
                Für allgemeine Anfragen,<br />Stornierung und Kündigung
              </div>
            </div>
            </div>
          </div>

          <div className="contact-card">
            <div className="card-header col-sm-12 col-md-4 col-xl-12">
              <img src={contact}></img>

              <span>Hotline</span>
            </div>
            <div className="card-body col-sm-12 col-md-8 col-xl-12">
              <div className="card-content">
                <div className="contact-header">Aus Österreich</div>
                <div className="contact-detail"><a href="tel:01 206 092 917">01 206 092 917</a></div>
              </div>
              <div className="card-content">
                <div className="contact-header">Aus dem Ausland</div>
                <div className="contact-detail"><a href="tel:+43 1 206 092 917">+43 1 206 092 917</a></div>
              </div>
            </div>
          </div>

          <div className="contact-card">
            <div className="card-header col-sm-12 col-md-4 col-xl-12">
              <a  target="_blank" rel="noreferrer">
                <img src={write}></img>
                <span>Kündigung</span>
              </a>
            </div>
            <div className="card-body col-sm-12 col-md-8 col-xl-12" >
              <div className="card-content">
                <div className="contact-detail">
                <Link
                  to={`/cancellation-form`}
                >
                  Bitte machen Sie Angaben zu Ihrem Versicherungsvertrag um den Geräteschutz zu beenden
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetailsComponent
