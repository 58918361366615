import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Chat = () => {

    useEffect(()=>{
        loadScript();
    },[])

   const loadScript = () =>{
        const scripts = [`https://${process.env.CHAT_CLOUD_FRONT_VARIABLE}.cloudfront.net/js/amazon-connect-chat-interface.js`, `https://${process.env.CHAT_CLOUD_FRONT_VARIABLE}.cloudfront.net/js/chatWidget.js`]; //AMS Philippines 
        for (let i = 0; i < scripts.length; i++) {
            let node = document.createElement('script');
            node.src = scripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementById('chatScriptWrapper').appendChild(node);
        }
    }

    return (
        <>
        <Helmet>
            <meta http-equiv="Cache-Control" content="public, max-age=0, must-revalidate" />
            <meta http-equiv="expires" content="-1" />
            <meta http-equiv="pragma" content="no-cache" />
            {/* <script src={`https://${process.env.CHAT_CLOUD_FRONT_VARIABLE}.cloudfront.net/js/amazon-connect-chat-interface.js`} type="text/javascript"></script>
            <script src={`https://${process.env.CHAT_CLOUD_FRONT_VARIABLE}.cloudfront.net/js/chatWidget.js`} type="text/javascript"></script> */}
        </Helmet>
        <button
              type="button" 
              className="btn btn-primary" 
              id="startChat"
            >
              <span>Live Chat</span>
            </button>
            <section id="section-chat">
                <div id="chatWrapper">
                    <div id="root"></div>
                </div>
                <div>
                    <div id="chatSpinner" className="spinner loading"></div>
                    <div id="chatScriptWrapper"></div>
                </div>
            </section>
        </>
    )

}

export default Chat;