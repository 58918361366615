import React from "react"
import bannerMobile from "../../assets/images/banner_home_mobile.jpg"
import bannerTablet from "../../assets/images/banner_home_tablet.jpg"
import bannerDesktop from "../../assets/images/banner_home_desktop.jpg"
import Chat from "./chat"

const HomeBanner = () => {
  return (
    <div className="home-banner">
      <img src={bannerMobile} className="display-mobile" alt="" />
      <img src={bannerTablet} className="display-tablet" alt="" />
      <img src={bannerDesktop} className="display-desktop" alt="" />
      <div className="banner-content">
        <div className="container">
          <p className="banner-heading font-bold">
            Immer in <strong>Kontakt</strong> <br />
          </p>
          <p className="banner-subheading font-light">
            Haben Sie Fragen oder benötigen Sie Hilfe?
          </p>
          <div className="button-wrapper">
            <Chat />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeBanner
